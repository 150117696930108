import {Layout, Menu} from "antd";
import {Link, useLocation} from "react-router-dom";
import {HomeOutlined, SettingOutlined} from '@ant-design/icons';

export function Sider() {
	let location = useLocation();
	const selectedMenuItem = location.pathname.split('/')[1]
	return (
		<Layout.Sider collapsible theme={'light'}>
			<Menu mode="inline" selectedKeys={[selectedMenuItem]}>
				<Menu.Item icon={<HomeOutlined/>} key="class-library">
					<Link to={"/class-library"}>
						Class Library
					</Link>
				</Menu.Item>
				<Menu.Item icon={<SettingOutlined />} key={"settings"}>
					<Link to={"/settings"}>
						Settings
					</Link>
				</Menu.Item>
			</Menu>
		</Layout.Sider>
	);
}
