import {
    ColoredColumns,
    EditableCellCheckbox,
    EditableCellFloatInput,
    EditableCellTextInput
} from "../../../components/tables";
import {Popconfirm, Space, Table, Tooltip} from "antd";
import {DeleteOutlined, DownOutlined, UpOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";


export function AttributesTable({dataSource, actions, dispatch, loading}) {
    const [selectedRecordId, setSelectedRecordId] = useState(null);
    const [totalWeight, setTotalWeight] = useState(0);

    useEffect(() => {
        setTotalWeight(dataSource.reduce((sum, item) => sum + (item.required ? item.weight : 0), 0));
    }, [dataSource])


    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (name, record) => (
                <EditableCellTextInput
                    value={name}
                    onSave={async value => dispatch(actions.editField(record.id, 'name', value))}
                />
            )
        },
        {
            title: 'Weight',
            dataIndex: 'weight',
            render: (weight, record) => {
                if (record.required) {
                    return (
                        <EditableCellFloatInput
                            value={weight}
                            onSave={async value => dispatch(actions.editField(record.id, 'weight', value))}
                        />
                    )
                }
            },
            width: '15%'
        },
        {
            title: '%',
            dataIndex: 'weight',
            render: (weight, record) => {
                if (record.required) {
                    return (
                        <span>{totalWeight > 0 ? (weight / totalWeight * 100).toFixed(1) : 0}%</span>
                    )
                }
            },
            width: '15%'
        },
        {
            title: 'Required',
            dataIndex: 'required',
            render: (required, record) => (
                <EditableCellCheckbox
                    value={required}
                    onSave={async value => dispatch(actions.editField(record.id, 'required', value))}
                />
            ),
            width: '10%'
        },
        {
            title: 'Actions',
            render: (_, record, idx) => (
                <Space>
                    <MoveActions
                        sourceIdx={idx}
                        allLength={dataSource.length}
                        onMoveUp={_ => dispatch(actions.moveUp(record.id))}
                        onMoveDown={_ => dispatch(actions.moveDown(record.id))}
                    />
                    <DeleteAction onDelete={_ => dispatch(actions.delete(record.id))}/>
                </Space>
            ),
            width: '10%'
        }
    ];

    const coloredColumns = new ColoredColumns(
        columns,
        record => record.id === selectedRecordId ? '#eee' : ''
    ).values();

    return (
        <Table
            loading={loading}
            columns={coloredColumns}
            dataSource={dataSource.sort((a, b) => a.order - b.order)}
            pagination={false}
            rowKey={r => r.id}
            rowClassName={() => 'editable-row'}
            size="small"
            onRow={(record) => ({
                onClick: _ => setSelectedRecordId(record.id)
            })}
        />
    )
}


function MoveActions({sourceIdx, allLength, onMoveUp, onMoveDown}) {
    return (
        <Space>
            {sourceIdx > 0 && (
                <Tooltip title={"Move Up"} placement={"bottom"} mouseEnterDelay={1}>
                    <a><UpOutlined onClick={onMoveUp}/></a>
                </Tooltip>
            )}
            {sourceIdx < (allLength - 1) && (
                <Tooltip title={"Move Down"} placement={"top"} mouseEnterDelay={1}>
                    <a><DownOutlined onClick={onMoveDown}/></a>
                </Tooltip>
            )}
        </Space>
    );
}


function DeleteAction({onDelete}) {
    return (
        <Popconfirm
            title="Sure to delete?"
            onConfirm={onDelete}
        >
            <Tooltip title={"Delete"} placement={"bottom"} mouseEnterDelay={1}>
                <a><DeleteOutlined /></a>
            </Tooltip>
        </Popconfirm>
    );
}
