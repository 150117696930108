import {Router, Route, Switch, Redirect} from "react-router-dom";
import {CommonLayout} from "./layouts/common/CommonLayout";
import {createBrowserHistory} from "history";
import {ClassLibrary, Settings} from './pages'

const history = createBrowserHistory();

function App() {
	return (
		<Router history={history}>
			<Switch>
				<Route path={"/"} exact render={_ => <Redirect to={'/class-library'}/>}/>

				<Route path={"/class-library"} exact>
					<CommonLayout>
						<ClassLibrary/>
					</CommonLayout>
				</Route>

				<Route path={"/settings"} exact>
					<CommonLayout>
						<Settings/>
					</CommonLayout>
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
