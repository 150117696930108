import FileDownload from "js-file-download"
import Api from '../../services/Api'
import {Button, Spin, message} from "antd";
import {useState} from "react";
import {DownloadOutlined} from "@ant-design/icons"

export function ExportTab() {
    const [loading, setLoading] = useState(false);

    const handleDownloadClick = () => {
        setLoading(true);
        Api.get('/data/export', {'responseType': 'blob'})
            .then(response => {
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1];
                FileDownload(response.data, filename, filename);
            })
            .catch(() => {
                message.error('Failed to export Class Library');
            })
            .finally(() => setLoading(false));
    }

    return (
        <Spin spinning={loading}>
            <Button onClick={handleDownloadClick}><DownloadOutlined/> Download Excel file</Button>
        </Spin>
    )
}
