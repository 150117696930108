import {message} from "antd";
import {useEffect, useState} from "react";
import Api from "../../../services/Api";
import {Button, Divider} from "antd/es";
import {Actions} from "./Actions";
import {AddNewAttributeModal} from "./AddAttributeModal";
import {AttributesTable} from "./Table";
import {FullHeightCard} from "../../../components/cards";


async function getAttributes(classId) {
    return new Promise((resolve, reject) => {
        Api.get(
            `/classes/${classId}/attributes`,
        ).then(response =>
            resolve(response.data.map(el => ({key: el.id, ...el})))
        ).catch(_ => reject());
    });
}


export function Attributes({selectedClass}) {
    const [attrs, setAttrs] = useState([])
    const [newAttrModalVisible, setNewAttrModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const actions = new Actions(selectedClass);

    useEffect(() => {
        if (selectedClass != null) {
            setLoading(true);
            getAttributes(selectedClass.id)
                .then(setAttrs)
                .catch(() => {
                    message.error('Error')
                })
                .finally(_ => {
                    setLoading(false)
                });
        }
    }, [selectedClass])

    const dispatch = async action => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            action.apply(
                attrs
            ).then(result => {
                setAttrs(result);
                resolve();
            }).catch(_ => {
                reject();
            }).finally(_ => {
                setLoading(false);
            });
        });
    }
    const handleNewAttrClick = e => {
        setNewAttrModalVisible(true);
    }

    if (selectedClass == null) {
        return (
            <FullHeightCard title={'Attributes'}>
                Select some Class to see its attributes
            </FullHeightCard>
        )
    } else {
        return (
            <>
                <AddNewAttributeModal
                    visible={newAttrModalVisible}
                    updateVisibility={setNewAttrModalVisible}
                    onFinish={async values => dispatch(actions.addNew(values))}
                />
                <FullHeightCard title={'Attributes of ' + selectedClass.name} extra={
                    <Button type="link" onClick={handleNewAttrClick}>Create new Attribute</Button>
                }>
                    <AttributesTable dataSource={attrs} dispatch={dispatch} actions={actions} loading={loading}/>
                </FullHeightCard>
            </>
        );
    }
}


function FlexLayout({head, body}) {
    return (
        <div style={{
            display: 'flex',
            flexFlow: 'column',
            height: '100%'
        }}>
            <div style={{
                flex: '0 1 auto'
            }}>
                {head}
            </div>
            <div style={{
                flex: '1 1 auto',
                overflowY: 'auto'
            }}>
                {body}
            </div>
        </div>
    );
}
