import {Form, Input, Spin} from "antd";
import {useState} from "react";

export function GenericForm({children, id, form, initialValues, onFinish, onValuesChange}) {
    const [loading, setLoading] = useState(false);

    return (
        <Form
            id={id}
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={initialValues}
            onFinish={onFinish}
            onValuesChange={onValuesChange}>
            <Spin spinning={loading}>
                {children}
            </Spin>
        </Form>
    );
}