import {message} from "antd";
import Api from "../../../services/Api";


export class Actions {
    constructor(selectedClass) {
        this._selectedClass = selectedClass;
    }

    addNew(values) {
        return new ActWithMessage(
            new ActAddNewAttribute(this._selectedClass.id, values),
            'Added',
            'Failed to add the attribute'
        );
    }

    delete(attrId) {
        return new ActWithMessage(
            new ActDeleteAttribute(this._selectedClass.id, attrId),
            'Deleted',
            'Failed to delete the attribute'
        );
    }

    editField(attrId, fieldName, fieldValue) {
        return new ActWithMessage(
            new ActEditField(this._selectedClass.id, attrId, fieldName, fieldValue),
            'Updated',
            'Failed to update the attribute'
        );
    }

    moveUp(attrId) {
        return new ActWithErrorMessage(
            new ActMoveUp(this._selectedClass.id, attrId),
            'Failed to move'
        )
    }

    moveDown(attrId) {
        return new ActWithErrorMessage(
            new ActMoveDown(this._selectedClass.id, attrId),
            'Failed to move'
        );
    }
}


class AttributeAction {
    async apply(prevData) {
        return prevData;
    }
}


class ActWrap extends AttributeAction {
    constructor(origin) {
        super();
        this._origin = origin;
    }

    async apply(prevData) {
        return this._origin.apply(prevData);
    }
}


class ActWithErrorMessage extends AttributeAction {
    constructor(origin, msg) {
        super();
        this._origin = origin;
        this._msg = msg;
    }

    async apply(prevData) {
        return new Promise((resolve, reject) => {
            this._origin.apply(
                prevData
            ).then(
                resolve
            ).catch(result => {
                message.error(this._msg);
                reject(result);
            })
        });
    }
}


class ActWithSuccessMessage extends ActWrap {
    constructor(origin, msg) {
        super();
        this._origin = origin;
        this._msg = msg;
    }

    async apply(prevData) {
        return new Promise((resolve, reject) => {
            this._origin.apply(
                prevData
            ).then(result => {
                message.success(this._msg);
                resolve(result);
            }).catch(reject)
        });
    }
}


class ActWithMessage extends ActWrap {
    constructor(origin, success, error) {
        super(
            new ActWithSuccessMessage(
                new ActWithErrorMessage(
                    origin,
                    error
                ),
                success
            )
        );
    }
}


class ActDeleteAttribute extends AttributeAction {
    constructor(classId, attrId) {
        super();
        this._classId = classId;
        this._attrId = attrId;
    }

    async apply(prevData) {
        return new Promise((resolve, reject) => {
            Api.delete(
                `/classes/${this._classId}/attributes/${this._attrId}`
            ).then(_ => {
                resolve(prevData.filter(el => el.id !== this._attrId));
            }).catch(reject);
        });
    }
}


class ActEditField extends AttributeAction {
    constructor(classId, attrId, fieldName, fieldValue) {
        super();
        this._classId = classId;
        this._attrId = attrId;
        this._fieldName = fieldName;
        this._fieldValue = fieldValue;
    }

    async apply(prevData) {
        return new Promise((resolve, reject) => {
            const body = {};
            body[this._fieldName] = this._fieldValue;

            Api.patch(
                `/classes/${this._classId}/attributes/${this._attrId}`,
                body
            ).then(response => {
                const updated = response.data;
                resolve(prevData.map(el => {
                    if (el.id === updated.id) {
                        return updated;
                    } else {
                        return el;
                    }
                }));
            }).catch(reject);
        });
    }
}


class ActAddNewAttribute extends AttributeAction {
    constructor(classId, values) {
        super();
        this._values = values;
        this._classId = classId;
    }

    async apply(prevData) {
        return new Promise((resolve, reject) => {
            Api.post(
                `/classes/${this._classId}/attributes`,
                {...this._values}
            ).then(response => {
                const added = response.data;
                resolve([...prevData, added]);
            }).catch(reject);
        });
    }
}


class ActBaseShift extends AttributeAction {
    constructor(classId, attrId, shift) {
        super();
        this._classId = classId;
        this._attrId = attrId;
        this._shift = shift;
    }

    async apply(prevData) {
        return new Promise((resolve, reject) => {
            Api.patch(
                `/classes/${this._classId}/attributes/${this._attrId}/${this._shift.direction}`
            ).then(response => {
                const attrById = response.data.reduce(
                    (map, el) => {
                        map[el.id] = el;
                        return map;
                    },
                    {}
                );
                resolve(prevData.map(el => {
                    if (attrById[el.id]) {
                        return attrById[el.id];
                    } else{
                        return el;
                    }
                }));
            }).catch(reject)
        });
    }
}


class ActMoveUp extends ActBaseShift {
    constructor(classId, attrId) {
        super(
            classId,
            attrId,
            {
                direction: 'up'
            }
        );
    }
}


class ActMoveDown extends ActBaseShift {
    constructor(classId, attrId) {
        super(
            classId,
            attrId,
            {
                direction: 'down'
            }
        );
    }
}
