import {useState} from "react";
import {Button, Modal, Spin} from "antd";
import {GenericForm} from "../../forms";

export function GenericFormModal(
    {
        children,
        okText = "Submit",
        cancelText = "Cancel",
        formId = 'generic-entity-form',
        form,
        initialValues,
        visible,
        updateVisibility,
        onFinish,
        onValuesChange,
        title,
        handleError = () => {
        },
        width=416
    }
) {
    const [submitting, setSubmitting] = useState(false);

    const onFormFinish = values => {
        setSubmitting(true);

        onFinish(
            values
        ).then(_ => {
            updateVisibility(false);
        }).catch(err => {
            handleError(err);
        }).finally(_ => {
            setSubmitting(false);
        });
    }

    return (
        <Modal
            open={visible}
            closable={false}
            confirmLoading={true}
            width={width}
            title={title}
            footer={[
                <Button key="cancel" onClick={_ => updateVisibility(false)}>{cancelText}</Button>,
                <Button type="primary" form={formId} key={"submit"} htmlType={"submit"}>{okText}</Button>,
            ]}
            modalRender={node => (
                <Spin spinning={submitting}>
                    {node}
                </Spin>
            )}
            destroyOnClose={true}
        >
            <GenericForm id={formId} form={form} initialValues={initialValues} onFinish={onFormFinish} onValuesChange={onValuesChange}>
                {children}
            </GenericForm>
        </Modal>
    );
}
