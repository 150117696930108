import {useState} from "react";
import {AsyncDataSource, ManualPagedTable} from "../../../components/tables/AsyncDataSourceTable";
import {FullHeightCard} from "../../../components/cards";
import Api from "../../../services/Api";
import {Button, Form, Input, Radio, message, Tooltip, Checkbox, Space} from "antd";
import {GenericFormModal} from "../../../components/modals";
import {CopyOutlined, EditOutlined} from "@ant-design/icons";
import {ColoredColumns} from "../../../components/tables";

class ClassesDataSource extends AsyncDataSource {
    async values(page, limit, filter) {
        return Api.get(
            '/classes',
            {params: {page: page, limit: limit, q: filter.query, type: filter.type.join()}}
        ).then(
            response => {
                return response.data.map(el => ({key: el.id, ...el}));
            }
        );
    }
}

export function ClassFormItems() {
    return (
        <>
            <Form.Item name="name" label="Name">
                <Input/>
            </Form.Item>
            <Form.Item name="type" label="Type">
                <Radio.Group>
                    <Radio value="equipment">equipment</Radio>
                    <Radio value="material">material</Radio>
                </Radio.Group>
            </Form.Item>
        </>
    )
}

export function Classes({selectedClass, setSelectedClass}) {
    const [createModalVisible, setCreateModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [copyModalVisible, setCopyModalVisible] = useState(false);
    const [classFormData, setClassFormData] = useState({})
    const [dataSource, setDataSource] = useState(new ClassesDataSource());
    const [updateTime, setUpdateTime] = useState(Date.now());
    const [filter, setFilter] = useState({query: '', type: ['equipment', 'material']});

    const handleNewClassClick = e => {
        setClassFormData({type: 'equipment'})
        setCreateModalVisible(true);
    }

    const handleEditClassClick = item => {
        setClassFormData(item);
        setEditModalVisible(true);
    }

    const handleCopyClassClick = item => {
        setClassFormData({
            name: item.name + '(copy)',
            type: item.type,
            copy_class_id: item.id
        });
        setCopyModalVisible(true);
    }

    const handleSelectClassClick = item => {
        setSelectedClass(item);
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            render: (id, item) => (
                <a onClick={_ => {handleSelectClassClick(item)}}>
                    {item.name}
                </a>
            )
        },
        {
            title: 'Type',
            dataIndex: 'type',
        },
        {
            title: 'Actions',
            dataIndex: 'id',
            render: (id, item) => (
                <Space>
                    <a onClick={_ => {handleEditClassClick(item)}}>
                        <Tooltip title="Edit" placement="bottom" mouseEnterDelay={1}>
                            <EditOutlined/>
                        </Tooltip>
                    </a>
                    <a onClick={_ => {handleCopyClassClick(item)}}>
                        <Tooltip title="Copy" placement="bottom" mouseEnterDelay={1}>
                            <CopyOutlined/>
                        </Tooltip>
                    </a>
                </Space>
            )
        }
    ];

    const coloredColumns = new ColoredColumns(
        columns,
        record => record.id === selectedClass?.id ? '#eee' : ''
    ).values();

    const onCreateFinish = async values => {
        return new Promise((resolve, reject) => {
            Api.post(
                '/classes',
                {...classFormData, ...values}
            ).then(response => {
                message.success("Created");
                setUpdateTime(Date.now());
                setSelectedClass(response.data);
                resolve(response)
            }).catch(reject)
        });
    }

    const onEditFinish = async values => {
        return new Promise((resolve, reject) => {
            console.log(values);
            Api.put(
                `/classes/${classFormData.id}`,
                {...values}
            ).then(response => {
                message.success("Updated");
                setUpdateTime(Date.now());
                resolve(response)
            }).catch(reject)
        });
    }

    const onSearch = searchText => {
        setFilter(prev => ({...prev, query: searchText}));
    }

    const onClassTypeSelect = value => {
        setFilter(prev => ({...prev, type: value}));
    }

    return (
        <>
            <GenericFormModal visible={createModalVisible} updateVisibility={setCreateModalVisible}
                              title="Create new Class" formId="newClassFormModal" onFinish={onCreateFinish}
                              initialValues={classFormData}
                              handleError={() => message.error('Failed to create new Class')}>
                <ClassFormItems/>
            </GenericFormModal>
            <GenericFormModal visible={editModalVisible} updateVisibility={setEditModalVisible}
                              title="Edit Class" formId="editClassFormModal" onFinish={onEditFinish}
                              initialValues={classFormData}
                              handleError={() => message.error('Failed to update the Class')}>
                <ClassFormItems/>
            </GenericFormModal>
            <GenericFormModal visible={copyModalVisible} updateVisibility={setCopyModalVisible}
                              title="Copy Class" formId="copyClassFormModal" onFinish={onCreateFinish}
                              initialValues={classFormData}
                              handleError={() => message.error('Failed to copy the Class')}>
                <ClassFormItems/>
            </GenericFormModal>            
            <FullHeightCard title="Classes" extra={
                <Button type="link" onClick={handleNewClassClick}>Create new Class</Button>
            }>
                <ManualPagedTable
                    id="classes-table"
                    size="small"
                    columns={coloredColumns}
                    filter={filter}
                    asyncDataSource={dataSource}
                    updateTime={updateTime}
                    header={
                        <>
                            <Input.Search
                                enterButton
                                onSearch={onSearch}
                                style={{marginBottom: '8px'}}
                            />
                            <Checkbox.Group
                                options={['equipment', 'material']}
                                defaultValue={['equipment', 'material']}
                                onChange={onClassTypeSelect}
                                style={{marginBottom: '8px'}}
                            />
                        </>
                    }
                />
            </FullHeightCard>
        </>
    );
}