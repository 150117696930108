import {Col, Row, Tabs} from "antd";
import {FullHeightCard} from "../../components/cards";
import {ExportTab} from "./ExportTab"
import {ImportTab} from "./ImportTab"

export function Settings() {
    const items = [
        { label: 'Export Class Library', key: 'export', children: <ExportTab/> },
        { label: 'Import Class Library', key: 'import', children: <ImportTab/> },
    ];

    return (
        <Row style={{height: "100%", maxHeight: "100%"}} gutter={[8, 8]}>
            <Col span={24} style={{height: "100%", maxHeight: "100%"}}>
                <FullHeightCard>
                    <Tabs defaultActiveKey="export" items={items}/>
                </FullHeightCard>
            </Col>
        </Row>
    )
}