import {Col, Row} from "antd";
import {Attributes} from "./attributes/Attributes";
import {Classes} from "./classes/Classes";
import {useState} from "react";

export function ClassLibrary() {
    const [selectedClass, setSelectedClass] = useState();
    return (
        <Row gutter={16} style={{height: "100%", maxHeight: "100%"}}>
            <Col span={8} style={{height: "100%", maxHeight: "100%"}}>
                <Classes selectedClass={selectedClass} setSelectedClass={setSelectedClass}/>
            </Col>
            <Col span={16} style={{height: "100%", maxHeight: "100%"}}>
                <Attributes selectedClass={selectedClass}/>
            </Col>
        </Row>
    );
}