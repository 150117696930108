import ReactDOM from "react-dom/client";
import axios from "axios";
import {message} from "antd";
import {Auth, UIConfigContext} from "@digatex/digatex-ui-lib"
import './index.less';
import App from './App';
import {UI_CONFIG} from "./constants"

axios.interceptors.request.use((config) => {
    if (Auth.isAuthenticated()) {
        const cb = () => {
            config.headers = { ...config.headers, ...Auth.headers()	}
            return Promise.resolve(config);
        };
        return Auth.updateToken(cb);
    }
});

axios.interceptors.response.use(
    response => response,
    error => {
        const status = error?.response?.status;
        if (status && (status >= 400 && status <= 599)) {
            const data = error.response.data;
            if (data.message != null) {
                message.error(data.message);
            }
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

const root = ReactDOM.createRoot(document.getElementById('root'));
Auth.init(() => root.render(
    <UIConfigContext.Provider value={UI_CONFIG}>
        <App/>
    </UIConfigContext.Provider>
));