import React, {useCallback, useEffect, useState} from "react";
import {Spin, Table} from "antd";


export class AsyncDataSource {
	async values(page, limit, filter) {
		return [];
	}
}

export function ManualPagedTable({
									 asyncDataSource,
									 filter,
									 header,
									 updateTime,
									 defaultLimit = 10,
									 spinDelay = 100,
									 showSizeChanger = true,
									 ...rest
								 }) {
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(defaultLimit);

	useEffect(() => {
		setPage(1);
	}, [limit, asyncDataSource]);

	const onPageChange = useCallback((page, pageSize) => {
		setPage(page);
		setLimit(pageSize);
	}, []);

	return (
		<TablePage
			asyncDataSource={asyncDataSource}
			updateTime={updateTime}
			page={page}
			limit={limit}
			filter={filter}
			header={header}
			onPageChange={onPageChange}
			spinDelay={spinDelay}
			showSizeChanger={showSizeChanger}
			{...rest}
		/>
	);
}


export function TablePage({
							  asyncDataSource,
							  page,
							  limit,
							  filter,
							  header,
							  onPageChange,
							  showSizeChanger = true,
							  spinDelay = 100,
							  updateTime,
							  ...rest
						  }) {
	const [curDataSource, setCurDataSource] = useState(new AsyncDataSource());
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	const [maxPage, setMaxPage] = useState(0);
	const [spinning, setSpinning] = useState(false);

	useEffect(() => {
		setCurDataSource(asyncDataSource);
		setMaxPage(0);
	}, [asyncDataSource]);

	useEffect(() => {
		setMaxPage(0);
		onPageChange(1, limit);
	}, [filter, limit]);

	useEffect(() => {
		let cancelled = false;
		setSpinning(true);

		curDataSource.values(page, limit, filter)
			.then(data => {
				if (!cancelled) {
					setData(data);

					setMaxPage(prev => {
						const newMax = Math.max(prev, page);

						if (page === newMax) {
							setTotal((newMax - 1) * limit + data.length);
						}

						return newMax;
					});
				}
			})
			.finally(_ => {
				if (!cancelled) {
					setSpinning(false);
				}
			});

		return () => {
			cancelled = true;
		}
	}, [curDataSource, page, limit, filter, updateTime ]);

	return (
		<Spin spinning={spinning} delay={spinDelay}>
			{header}
			<Table
				{...rest}
				dataSource={data.slice(0, limit)}
				pagination={{
					position: 'bottomCenter',
					showSizeChanger: showSizeChanger,
					current: page,
					total: total,
					pageSize: limit,
					onChange: onPageChange
				}}
			/>
		</Spin>
	);
}
