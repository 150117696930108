import {Checkbox, Input, InputNumber} from "antd";
import {useState} from "react";


export function EditableCellIntInput({value, onSave}) {
    return (
        <EditableCellInput
            value={value}
            onSave={onSave}
            map={parseInt}
            renderInput={({save}) => (
                <InputNumber
                    autoFocus
                    style={{margin: 0}}
                    defaultValue={value}
                    onPressEnter={save}
                    onBlur={save}
                    type="number"
                />
            )}
        />
    );
}


export function EditableCellFloatInput({value, onSave}) {
    return (
        <EditableCellInput
            value={value}
            onSave={onSave}
            map={parseFloat}
            renderInput={({save}) => (
                <InputNumber
                    autoFocus
                    style={{margin: 0}}
                    onPressEnter={save}
                    onBlur={save}
                    defaultValue={value}
                    stringMode
                    type="number"
                />
            )}
        />
    );
}


export function EditableCellTextInput({value, onSave}) {
    return (
        <EditableCellInput
            value={value}
            onSave={onSave}
            renderInput={({save}) => (
                <Input
                    autoFocus
                    style={{margin: 0}}
                    defaultValue={value}
                    onPressEnter={save}
                    onBlur={save}
                />
            )}
        />
    );
}


export function EditableCellInput({value, onSave, renderInput, map = v => v}) {
    const [editing, setEditing] = useState(false);

    const toggleEdit = () => {
        setEditing(!editing);
    };

    const save = (e) => {
        const new_value = map(e.target.value);
        if (value !== new_value) {
            onSave(new_value).finally(toggleEdit);
        }
    }

    const renderEditing = () => renderInput({save});

    const renderStatic = () => (
        <div
            className="editable-cell-value-wrap"
            style={{
                paddingRight: 24,
            }}
            onClick={toggleEdit}
        >
            {value}
        </div>
    );

    return editing ? renderEditing() : renderStatic();
}


export function EditableCellCheckbox({value, onSave}) {
    const save = (e) => {
        onSave(e.target.checked);
    }

    return (
        <Checkbox defaultChecked={value} onChange={save}/>
    );
}
