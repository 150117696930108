import {GenericFormModal} from "../../../components/modals";
import {Checkbox, Form, Input, InputNumber} from "antd";
import {useEffect, useState} from "react";


const emptyAttribute = {
    name: '',
    weight: 1,
    required: true
}


export function AddNewAttributeModal({visible, updateVisibility, onFinish}) {
    const [required, setRequired] = useState(true);
    const [form] = Form.useForm();

    useEffect(() => {
        if (visible) {
            setRequired(true);
            form.resetFields();
        }
    }, [visible])

    const onValuesChange = (changedValues) => {
        if ('required' in changedValues) {
            setRequired(changedValues.required);
            if (!changedValues.required) {
                form.setFieldsValue({weight: 0});
            }
        }
    }

    return (
        <GenericFormModal
            title={"New Attribute"}
            visible={visible}
            updateVisibility={updateVisibility}
            initialValues={emptyAttribute}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            form={form}
        >
            <Form.Item
                name='name'
                label='Name'
                required
                rules={[{
                    required: true
                }]}
            >
                <Input/>
            </Form.Item>
            <Form.Item name='required' label='Required' valuePropName="checked">
                <Checkbox/>
            </Form.Item>
            <Form.Item
                name='weight'
                label='Weight'
                required={required}
                getValueFromEvent={parseFloat}
                rules={[{
                    validator: async (_, value) => {
                        if (isNaN(value)) return Promise.reject("'weight' is required");
                        if (value < 0) return Promise.reject("'weight' cannot be negatvie");
                        return Promise.resolve();
                    }
                }]}
            >
                <InputNumber stringMode type="number" disabled={!required}/>
            </Form.Item>
        </GenericFormModal>
    );
}
